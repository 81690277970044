import React from 'react'

const IdeaIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      x="0px"
      y="0px"
      fill={'#2D6CFF'}
      {...props}
    >
      <title>Lightbulb Icon - LaunchPlan Business Plan Software for Entrepreneurs</title>
      <g>
        <path d="M45.326,8.087A20,20,0,0,0,12.1,20.983a19.816,19.816,0,0,0,6.745,17.065A15.206,15.206,0,0,1,23.034,44H23a1,1,0,0,0,0,2h.662A13.674,13.674,0,0,1,24,49v6a3,3,0,0,0,3,3h1a4,4,0,0,0,8,0h1a3,3,0,0,0,3-3V49A14.007,14.007,0,0,1,40.328,46H41a1,1,0,0,0,0-2h-.06a14.54,14.54,0,0,1,4.106-5.857,19.981,19.981,0,0,0,.28-30.056ZM38,52H26V50H38Zm-6,8a2,2,0,0,1-2-2h4A2,2,0,0,1,32,60Zm5-4H27a1,1,0,0,1-1-1V54H38v1A1,1,0,0,1,37,56Zm1.031-8H25.968a15.664,15.664,0,0,0-.276-2H38.3A15.913,15.913,0,0,0,38.031,48Zm5.71-11.373A16.507,16.507,0,0,0,38.821,44H33V37h1A13.015,13.015,0,0,0,47,24a1,1,0,0,0-1-1H44a12.993,12.993,0,0,0-11,6.1V23.941A9.01,9.01,0,0,0,41,15a1,1,0,0,0-1-1A9,9,0,0,0,32,18.9,9,9,0,0,0,24,14a1,1,0,0,0-1,1,9.01,9.01,0,0,0,8,8.941V29.1A12.993,12.993,0,0,0,20,23H18a1,1,0,0,0-1,1A13.015,13.015,0,0,0,30,37h1v7H25.158a17.124,17.124,0,0,0-5-7.458,18,18,0,1,1,23.582.085ZM33.045,35A11.015,11.015,0,0,1,44,25h.955A11.015,11.015,0,0,1,34,35Zm.039-13.084a7.015,7.015,0,0,1,5.832-5.832A7.015,7.015,0,0,1,33.084,21.916Zm-2.168,0a7.015,7.015,0,0,1-5.832-5.832A7.015,7.015,0,0,1,30.916,21.916ZM30.955,35H30A11.015,11.015,0,0,1,19.045,25H20A11.015,11.015,0,0,1,30.955,35Z"></path>
      </g>
    </svg>
  )
}

export default IdeaIcon
