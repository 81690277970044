import React from 'react'

const GraduationCapIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 150 150"
      fill={"#2D6CFF"}
      {...props}
    >
      <title>Graduation Cap Icon - LaunchPlan Business Plan Software for Colleges</title>
      <path d="M135,48.7c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1  c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1  c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0l-58-18.9c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1  c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0l-58,18.9  c-0.8,0.3-1.4,1-1.4,1.9s0.6,1.6,1.4,1.9l18.6,6.1v30.9c0,1.1,0.9,2,2,2c9.5,0,18.7,3.3,26.1,9.3l10.7,8.7c0,0,0,0,0,0  c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0  c0,0,0,0,0,0s0,0,0,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.2  L87,98.6c4.4-3.6,9.5-6.2,15-7.7v11.4c-2.4,0.7-4.2,2.9-4.2,5.6v8.8c0,3.2,2.6,5.8,5.8,5.8h0.8c3.2,0,5.8-2.6,5.8-5.8v-8.8  c0-2.6-1.8-4.9-4.2-5.6V89.9c2.3-0.4,4.7-0.6,7.1-0.6c1.1,0,2-0.9,2-2V56.5l18.5-6.1c0,0,0,0,0,0l0,0c0.2-0.1,0.3-0.1,0.5-0.2  c0,0,0.1,0,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2  C135,48.8,135,48.7,135,48.7z M106.2,116.6c0,1-0.8,1.8-1.8,1.8h-0.8c-1,0-1.8-0.8-1.8-1.8v-8.8c0-1,0.8-1.8,1.8-1.8h0.8  c1,0,1.8,0.8,1.8,1.8V116.6z M75,31.7l51.6,16.8L104,55.9l-28.4-9.3c-1.1-0.3-2.2,0.2-2.5,1.3c-0.3,1.1,0.2,2.2,1.3,2.5L97.6,58  L75,65.3L23.5,48.5L75,31.7z M84.5,95.5l-7.5,6.1v-6.9c0-1.1-0.9-2-2-2s-2,0.9-2,2v6.9l-7.5-6.1c-7.5-6.1-16.9-9.7-26.6-10.1V57.8  l35.4,11.6c0.2,0.1,0.4,0.1,0.6,0.1s0.4,0,0.6-0.1l26.4-8.6v26C95.6,88.3,89.6,91.3,84.5,95.5z M111.1,85.4  c-1.7,0.1-3.4,0.3-5.1,0.5V59.4l5.1-1.7V85.4z"></path>
    </svg>
  )
}

export default GraduationCapIcon
