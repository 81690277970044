import React from "react"
import { Helmet } from "react-helmet"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PublicLayout from "../components/layouts/PublicLayout"
import ContactUsForm from "../components/forms/ContactUsForm"
import Button from "../components/button"
import DogtagIcon from "../images/marketing/dogtags"
import GraduationCapIcon from "../images/marketing/graduationCap"
import IdeaIcon from "../images/marketing/idea"

const styles = theme => ({
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  reverse: {
    "& $icon": {
      [theme.breakpoints.up("md")]: {
        margin: "0 0 0 auto",
      },
    },
  },
  first: {},
  textImageModule: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `'text2' 'text'`,
    gridGap: "2rem",
    alignItems: "center",
    padding: "4rem 0",
    maxWidth: 500,
    margin: "0 auto",

    "&$reverse": {
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 2fr",
        gridTemplateAreas: `'text2 text'`,
      },
    },

    "&$first": {
      paddingTop: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "2fr 1fr",
      gridTemplateAreas: `'text text2'`,
      gridGap: "5rem",
      maxWidth: 1000,
      padding: "6rem 2rem",
    },
  },
  text2: {
    gridArea: "text2",
    justifySelf: "end",
    maxWidth: 450,
    width: "100%",
  },
  text: {
    gridArea: "text",
    maxWidth: 600,
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 100,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  icon: {
    margin: "0 auto",
    width: 140,

    [theme.breakpoints.up("md")]: {
      margin: "0 auto 0 0",
    },
  },
  spacer: {
    marginTop: '1rem',
  }
})

const SolutionsPage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"LaunchPlan was built for..."}
      canonicalUrl="https://launchplan.com/solutions"
    >
      <Helmet>
        <title>Solutions for Creating a Business Plan | LaunchPlan</title>
        <meta
          name="description"
          content="LaunchPlan business plan software offers smart and simple solutions for entrepreneurs, colleges, and veterans looking to build a better business plan."
        />
        <meta
          property="og:title"
          content="Solutions for Creating a Business Plan | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/solutions" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section>
        <div
          className={classnames(
            classes.textImageModule,
            classes.reverse,
            classes.borderBottom
          )}
        >
          <div className={classes.text}>
            <Typography variant="h2" className={classes.headline}>
              {"Entrepreneurs and small businesses"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography gutterBottom className={classes.description}>
              {
                "We understand how difficult it can be to get your startup or small business going. LaunchPlan is the most intuitive business planning software out there. First time writing a business plan? No problem — LaunchPlan guides you through each step. From your mission statement to automated financial forecasting, we help you communicate your vision with a viable plan."
              }
            </Typography>
            <div className={classes.spacer}>
              <Button color="dark" href="/real-estate-business-plan-software">
                {"Real estate business plans"}
              </Button>
            </div>
            <div className={classes.spacer}>
              <Button color="dark" href="/salon-spa-studio-business-plan-software">
                {"Salon and spa business plans"}
              </Button>
            </div>
            <div className={classes.spacer}>
              <Button color="dark" href="/restaurant-business-plan-software">
                {"Restaurant business plans"}
              </Button>
            </div>
            <div className={classes.spacer}>
              <Button variant="primary" href="/pricing">
                {"Get started"}
              </Button>
            </div>
          </div>
          <div className={classes.icon}>
            <IdeaIcon alt="Lightbulb Icon - LaunchPlan Business Plan Software for Entrepreneurs" />
          </div>
        </div>
        <div
          className={classnames(classes.textImageModule, classes.borderBottom)}
        >
          <div className={classes.text}>
            <Typography variant="h2" className={classes.headline}>
              {"Colleges and universities"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography gutterBottom className={classes.description}>
              {
                "We offer great promotions to professors of business courses and other faculty leading entrepreneurial programs at colleges and universities. Our educational software provides students with detailed definitions, examples, and tutorials for every section of a business plan. It’s perfect for classrooms, business plan competitions, incubators, and many other educational initiatives. If you would like to learn more, contact us today."
              }
            </Typography>
            <Button
              variant="primary"
              // onClick={() =>
              //   window.scrollTo({
              //     top: document
              //       .getElementById("contact-us")
              //       .offsetTop - 50,
              //     left: 100,
              //     behavior: "smooth",
              //   })
              // }
              href='mailto:team@launchplan.com?subject=Learn more about LaunchPlan'
            >
              Send us a message
            </Button>
          </div>
          <div className={classes.icon}>
            <GraduationCapIcon alt="Graduation Cap Icon - LaunchPlan Business Plan Software for Colleges" />
          </div>
        </div>
        <div className={classnames(classes.textImageModule, classes.reverse)}>
          <div className={classes.text}>
            <Typography variant="h2" className={classes.headline}>
              {"Veterans"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography gutterBottom className={classes.description}>
              {
                "LaunchPlan proudly supports our veterans. As a way of showing our appreciation, we offer veterans looking for business plan help with free access to LaunchPlan for at least three months as well as ongoing discounts through any VA program. Tell your local VA office to contact us now to learn more."
              }
            </Typography>
            <Button
              variant="primary"
              // onClick={() =>{
              //   window.scrollTo({
              //     top: document
              //     .getElementById("contact-us")
              //     .offsetTop - 50,
              //     left: 100,
              //     behavior: "smooth",
              //   })
              // }
              // }
              href='mailto:team@launchplan.com?subject=Learn more about LaunchPlan'
            >
              Reach out
            </Button>
          </div>
          <div className={classes.icon}>
            <DogtagIcon alt="Military Dog Tag Icon - LaunchPlan Business Plan Software for Veterans" />
          </div>
        </div>
      </section>
      <ContactUsForm />
    </PublicLayout>
  )
}

export default withStyles(styles)(SolutionsPage)
