import React from 'react'

const DogtagIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 61"
      version="1.1"
      x="0px"
      y="0px"
      fill={"#2D6CFF"}
      {...props}
    >
      <title>Military Dog Tag Icon - LaunchPlan Business Plan Software for Veterans</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-365.000000, -1243.000000)" fill={"#2D6CFF"}>
          <g transform="translate(365.000000, 1243.000000)">
            <path d="M3.2007,2.0281 C2.9447,2.0281 2.6887,2.1251 2.4937,2.3201 C2.1047,2.7101 2.1047,3.3441 2.4937,3.7341 L5.3227,6.5621 C5.7107,6.9521 6.3467,6.9521 6.7367,6.5621 C6.9257,6.3741 7.0297,6.1231 7.0297,5.8551 C7.0297,5.5891 6.9257,5.3371 6.7367,5.1481 L3.9087,2.3201 C3.7137,2.1251 3.4567,2.0281 3.2007,2.0281 M6.0297,8.8531 C5.2617,8.8541 4.4927,8.5611 3.9087,7.9761 L1.0797,5.1481 C-0.0893,3.9781 -0.0893,2.0761 1.0787,0.9061 C2.2477,-0.2649 4.1517,-0.2649 5.3227,0.9061 L8.1507,3.7341 C8.7177,4.3001 9.0297,5.0541 9.0297,5.8551 C9.0297,6.6571 8.7177,7.4101 8.1507,7.9761 C7.5657,8.5611 6.7977,8.8531 6.0297,8.8531"></path>
            <path d="M11.6861,10.5135 C11.4301,10.5135 11.1741,10.6105 10.9791,10.8055 C10.5901,11.1955 10.5901,11.8295 10.9791,12.2195 L13.8081,15.0475 L13.8081,15.0475 C14.1851,15.4265 14.8441,15.4265 15.2211,15.0475 C15.4111,14.8585 15.5151,14.6085 15.5151,14.3405 C15.5151,14.0745 15.4111,13.8235 15.2221,13.6335 L12.3941,10.8055 C12.1991,10.6105 11.9421,10.5135 11.6861,10.5135 M14.5151,17.3405 C13.7131,17.3405 12.9601,17.0285 12.3941,16.4615 L9.5651,13.6335 C8.3961,12.4635 8.3961,10.5615 9.5641,9.3915 C10.7341,8.2215 12.6371,8.2205 13.8081,9.3915 L16.6361,12.2195 C17.2031,12.7855 17.5151,13.5395 17.5151,14.3405 C17.5151,15.1425 17.2021,15.8965 16.6351,16.4625 C16.0691,17.0285 15.3161,17.3405 14.5151,17.3405"></path>
            <path d="M10.272,11.0984 C10.016,11.0984 9.76,11.0004 9.565,10.8054 L6.737,7.9764 C6.346,7.5854 6.346,6.9524 6.737,6.5624 C7.127,6.1714 7.76,6.1714 8.151,6.5624 L10.979,9.3914 C11.37,9.7824 11.37,10.4144 10.979,10.8054 C10.784,11.0004 10.528,11.0984 10.272,11.0984"></path>
            <path d="M20.1714,18.9988 C19.9154,18.9988 19.6594,19.0958 19.4644,19.2908 C19.2754,19.4798 19.1714,19.7298 19.1714,19.9978 C19.1714,20.2648 19.2754,20.5158 19.4644,20.7048 L22.2934,23.5328 L22.2934,23.5328 C22.6704,23.9118 23.3294,23.9118 23.7064,23.5328 C23.8964,23.3438 24.0004,23.0938 24.0004,22.8258 C24.0004,22.5598 23.8964,22.3088 23.7074,22.1188 L20.8794,19.2908 C20.6844,19.0958 20.4274,18.9988 20.1714,18.9988 M23.0004,25.8258 C22.1984,25.8258 21.4454,25.5138 20.8794,24.9468 L18.0504,22.1188 C17.4834,21.5528 17.1714,20.7988 17.1714,19.9978 C17.1714,19.1958 17.4834,18.4418 18.0504,17.8758 C19.2194,16.7068 21.1234,16.7068 22.2934,17.8768 L25.1214,20.7048 C25.6884,21.2708 26.0004,22.0248 26.0004,22.8258 C26.0004,23.6278 25.6874,24.3818 25.1204,24.9478 C24.5544,25.5138 23.8014,25.8258 23.0004,25.8258"></path>
            <path d="M18.7574,19.5838 C18.5014,19.5838 18.2454,19.4858 18.0504,19.2908 L15.2214,16.4618 C14.8314,16.0708 14.8314,15.4378 15.2214,15.0478 C15.6124,14.6568 16.2454,14.6568 16.6354,15.0478 L19.4644,17.8768 C19.8554,18.2678 19.8554,18.8998 19.4644,19.2908 C19.2694,19.4858 19.0134,19.5838 18.7574,19.5838"></path>
            <path d="M19.7041,23.7723 C18.7661,24.0393 17.8591,24.3703 17.0001,24.7593 L15.9351,25.2443 C15.3671,25.5023 15.0001,26.0723 15.0001,26.6973 L15.0001,54.3573 C15.0001,54.9823 15.3671,55.5513 15.9351,55.8103 L17.1621,56.3683 C22.1431,58.6313 27.8561,58.6313 32.8381,56.3683 L34.0641,55.8103 C34.6331,55.5513 35.0001,54.9823 35.0001,54.3573 L35.0001,26.6973 C35.0001,26.0723 34.6331,25.5023 34.0651,25.2443 L34.0641,25.2443 L32.9991,24.7593 C30.7751,23.7493 28.4221,23.1773 25.9921,23.0553 C25.9401,23.7533 25.6421,24.4263 25.1211,24.9473 C23.9881,26.0803 22.0121,26.0803 20.8781,24.9473 L19.7041,23.7723 Z M25.0001,60.0653 C22.0441,60.0653 19.0881,59.4403 16.3341,58.1883 L15.1071,57.6303 C13.8281,57.0493 13.0001,55.7643 13.0001,54.3573 L13.0001,26.6973 C13.0001,25.2903 13.8281,24.0043 15.1081,23.4233 L16.1731,22.9393 C17.3051,22.4243 18.5111,22.0033 19.7571,21.6873 C20.0981,21.6023 20.4611,21.7003 20.7101,21.9503 L22.2931,23.5333 C22.6711,23.9123 23.3291,23.9123 23.7061,23.5333 C23.9971,23.2423 24.0791,22.8093 23.9141,22.4283 C23.7811,22.1213 23.8101,21.7663 23.9941,21.4853 C24.1761,21.2053 24.4881,21.0343 24.8241,21.0313 C27.9571,21.0393 30.9861,21.6493 33.8271,22.9393 L34.8921,23.4233 C36.1721,24.0043 37.0001,25.2903 37.0001,26.6973 L37.0001,54.3573 C37.0001,55.7643 36.1721,57.0493 34.8921,57.6303 L33.6661,58.1883 C30.9121,59.4403 27.9561,60.0653 25.0001,60.0653 L25.0001,60.0653 Z"></path>
            <path d="M30,33.0272 L20,33.0272 C19.448,33.0272 19,32.5802 19,32.0272 C19,31.4742 19.448,31.0272 20,31.0272 L30,31.0272 C30.552,31.0272 31,31.4742 31,32.0272 C31,32.5802 30.552,33.0272 30,33.0272"></path>
            <path d="M30,39.0272 L20,39.0272 C19.448,39.0272 19,38.5802 19,38.0272 C19,37.4742 19.448,37.0272 20,37.0272 L30,37.0272 C30.552,37.0272 31,37.4742 31,38.0272 C31,38.5802 30.552,39.0272 30,39.0272"></path>
            <path d="M30,45.0272 L20,45.0272 C19.448,45.0272 19,44.5802 19,44.0272 C19,43.4742 19.448,43.0272 20,43.0272 L30,43.0272 C30.552,43.0272 31,43.4742 31,44.0272 C31,44.5802 30.552,45.0272 30,45.0272"></path>
            <path d="M30,51.0272 L20,51.0272 C19.448,51.0272 19,50.5802 19,50.0272 C19,49.4742 19.448,49.0272 20,49.0272 L30,49.0272 C30.552,49.0272 31,49.4742 31,50.0272 C31,50.5802 30.552,51.0272 30,51.0272"></path>
            <path d="M46.7993,2.0281 C46.5433,2.0281 46.2873,2.1251 46.0913,2.3201 L43.2633,5.1481 C43.0743,5.3371 42.9703,5.5891 42.9703,5.8551 C42.9703,6.1231 43.0743,6.3741 43.2633,6.5621 C43.6523,6.9501 44.2873,6.9521 44.6773,6.5621 L47.5063,3.7341 C47.8953,3.3441 47.8953,2.7101 47.5063,2.3201 C47.3113,2.1251 47.0553,2.0281 46.7993,2.0281 M43.9703,8.8531 C43.2023,8.8531 42.4343,8.5611 41.8493,7.9761 C41.2823,7.4101 40.9703,6.6571 40.9703,5.8551 C40.9703,5.0541 41.2823,4.3001 41.8483,3.7341 L44.6773,0.9061 C45.8473,-0.2649 47.7503,-0.2649 48.9203,0.9061 C50.0893,2.0761 50.0893,3.9781 48.9213,5.1481 L46.0913,7.9761 C45.5073,8.5611 44.7383,8.8531 43.9703,8.8531"></path>
            <path d="M38.314,10.5135 C38.058,10.5135 37.802,10.6105 37.606,10.8055 L34.778,13.6335 C34.589,13.8235 34.485,14.0745 34.485,14.3405 C34.485,14.6085 34.589,14.8585 34.778,15.0465 C35.157,15.4275 35.815,15.4265 36.192,15.0475 L36.192,15.0475 L39.021,12.2195 C39.41,11.8295 39.41,11.1955 39.021,10.8055 C38.826,10.6105 38.57,10.5135 38.314,10.5135 M35.485,17.3405 C34.684,17.3405 33.931,17.0285 33.364,16.4615 C32.798,15.8965 32.485,15.1425 32.485,14.3405 C32.485,13.5395 32.797,12.7855 33.364,12.2195 L36.192,9.3915 C37.362,8.2205 39.265,8.2215 40.435,9.3915 C41.604,10.5615 41.604,12.4635 40.436,13.6335 L37.606,16.4615 C37.04,17.0285 36.287,17.3405 35.485,17.3405"></path>
            <path d="M39.7281,11.0984 C39.4721,11.0984 39.2161,11.0004 39.0211,10.8054 C38.6301,10.4144 38.6301,9.7824 39.0211,9.3914 L41.8491,6.5624 C42.2391,6.1714 42.8731,6.1714 43.2631,6.5624 C43.6541,6.9524 43.6541,7.5854 43.2631,7.9764 L40.4351,10.8054 C40.2401,11.0004 39.9841,11.0984 39.7281,11.0984"></path>
            <path d="M31.2427,19.5838 C30.9867,19.5838 30.7307,19.4858 30.5357,19.2908 C30.1447,18.8998 30.1447,18.2678 30.5357,17.8768 L33.3647,15.0478 C33.7547,14.6568 34.3877,14.6568 34.7787,15.0478 C35.1687,15.4378 35.1687,16.0708 34.7787,16.4618 L31.9497,19.2908 C31.7547,19.4858 31.4987,19.5838 31.2427,19.5838"></path>
            <path d="M27.2559,21.1561 C28.0729,21.2421 28.8839,21.3761 29.6849,21.5551 L30.5359,20.7051 C30.9249,20.3151 30.9249,19.6801 30.5349,19.2911 C30.1449,18.9001 29.5109,18.9001 29.1209,19.2911 L27.2559,21.1561 Z M29.9969,23.6581 C29.9149,23.6581 29.8329,23.6481 29.7509,23.6281 C28.2539,23.2491 26.7089,23.0501 25.1579,23.0381 C24.8229,23.0351 24.5119,22.8661 24.3289,22.5861 C24.1459,22.3051 24.1139,21.9531 24.2459,21.6451 C24.3979,21.2891 24.6109,20.9721 24.8789,20.7051 L27.7069,17.8771 C28.8769,16.7061 30.7799,16.7071 31.9499,17.8771 C33.1189,19.0471 33.1189,20.9491 31.9499,22.1191 L30.7039,23.3651 C30.5139,23.5541 30.2579,23.6581 29.9969,23.6581 L29.9969,23.6581 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DogtagIcon
